import { faImages } from '@fortawesome/pro-light-svg-icons';
import React, { FC } from 'react';
import ContentEditor from '../..';
import { getValue } from '../../../../lib/block';
import { BlockProps } from '../../../../RbKit';

const Block: FC<BlockProps> = ({ data, onBlockChange }): JSX.Element => {
    const handleUpdate = (items: any[]): void => {
        const newData = { ...data };
        newData.fields[0].value = items;
        if (onBlockChange) {
            onBlockChange(newData);
        }
    }

    return (
        <div style={{ padding: '1rem' }}>
            <ContentEditor
                content={{ blocks: getValue(data, 'images') }}
                onUpdate={handleUpdate}
                availableBlocks={['Image']}
                addLabel="Add image"
                flex
            />
        </div>
    );
}

const data = {
    Block,
    icon: faImages,
    getData: () => ({
        block: 'imagegallery',
        sidebar: false,
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'images',
            type: 'contenteditor',
            value: [],
        }],
    })
};

export default data;
