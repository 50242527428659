import { faColumns } from '@fortawesome/pro-light-svg-icons';
import React, { FC } from 'react';
import ContentEditor from '../..';
import { getValue } from '../../../../lib/block';
import { BlockProps } from '../../../../RbKit';

const Block: FC<BlockProps> = ({ data, onBlockChange }): JSX.Element => {
    const handleUpdate = (items: any[], index: number): void => {
        const newData = { ...data };
        newData.fields[index].value = items;
        if (onBlockChange) {
            onBlockChange(newData);
        }
    }

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, margin: '1rem' }}>
                <ContentEditor
                    content={{ blocks: getValue(data, 'left') }}
                    onUpdate={(items) => handleUpdate(items, 0)}
                />
            </div>
            <div style={{ flex: 1, margin: '1rem' }}>
                <ContentEditor
                    content={{ blocks: getValue(data, 'right') }}
                    onUpdate={(items) => handleUpdate(items, 1)}
                />
            </div>
        </div>
    );
}

const data = {
    Block,
    icon: faColumns,
    getData: () => ({
        block: 'columns',
        sidebar: false,
        fields: [{
            id: 'left',
            type: 'contenteditor',
            value: [],
        }, {
            id: 'right',
            type: 'contenteditor',
            value: [],
        }],
    })
};

export default data;
