import Accordion from './Accordion';
import Columns from './Columns';
import Divider from './Divider';
import Icon from './Icon';
import Image from './Image';
import ImageGallery from './ImageGallery';
import ImageText from './ImageText';
import Label from './Label';
import Quote from './Quote';
import Text from './Text';
import Video from './Video';

export const BLOCKS: any = {
    Accordion,
    Columns,
    Divider,
    Icon,
    Image,
    Imagegallery: ImageGallery,
    Imagetext: ImageText,
    Label,
    Quote,
    Text,
    Video,
};

export const blockComponents: any = {
    accordion: Accordion.Block,
    columns: Columns.Block,
    divider: Divider.Block,
    icon: Icon.Block,
    image: Image.Block,
    imagegallery: ImageGallery.Block,
    imagetext: ImageText.Block,
    label: Label.Block,
    quote: Quote.Block,
    text: Text.Block,
    video: Video.Block,
};
