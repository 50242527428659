import { faColumns } from '@fortawesome/pro-light-svg-icons';
import React, { FC } from 'react';
import parse from 'html-react-parser';
import ImageThumb from '../../../../components/ImageThumb';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import styles from './styles.module.scss';

const Block: FC<BlockProps> = ({ data }): JSX.Element => {
    return (
        <Segment className={`${styles.segment} ${getValue(data, 'position') === 'left' ? '' : styles.right}`}>
            <div className={styles.thumb}>
                <ImageThumb
                    square
                    image={getValue(data, 'image')}
                />
            </div>
            <div className={styles.container}>
                    {parse(getValue(data, 'content') || '')}
            </div>
        </Segment>
    );
}

const data = {
    Block,
    icon: faColumns,
    settings: [{
        id: 'bg',
        type: 'dropdown',
        label: 'Background color',
        default: 'white',
        props: {
            options: [{
                text: 'White',
                value: 'white',
            }, {
                text: 'Orange',
                value: 'orange',
            }]
        }
    }, {
        id: 'alignment',
        type: 'dropdown',
        label: 'Aligment',
        default: 'flex-start',
        props: {
            options: [{
                text: 'Top',
                value: 'flex-start',
            }, {
                text: 'Middle',
                value: 'center',
            }, {
                text: 'Bottom',
                value: 'flex-end',
            }]
        }
    }],
    getData: () => ({
        block: 'imagetext',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'content',
            type: 'wysiwyg',
            label: 'Content',
            value: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec hendrerit augue nisl, vel dictum quam efficitur in. Vivamus aliquet lorem in turpis pretium, ac consectetur massa molestie. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec nisl nisi, vehicula at risus et, convallis convallis lacus. Mauris ut pellentesque quam. Cras eros est, fermentum non lorem id, porta ultricies enim. Duis id sapien et erat dictum vulputate congue quis erat. Quisque id finibus mi.</p>',
        }, {
            id: 'image',
            type: 'image',
            label: 'Image',
            value: '',
        }, {
            id: 'zoom',
            type: 'checkbox',
            label: 'Enable zoom',
            value: false,
        }, {
            id: 'alt',
            type: 'text',
            label: 'Alt text',
            value: '',
        }, {
            id: 'position',
            type: 'dropdown',
            label: 'Image position',
            value: 'left',
            props: {
                options: [{
                    text: 'Left',
                    value: 'left',
                }, {
                    text: 'Right',
                    value: 'right',
                }]
            }
        },  {
            id: 'link',
            type: 'text',
            label: 'Image link',
            value: '',
        }, {
            id: 'width',
            type: 'text',
            label: 'Image width (px or %)',
            value: '33%',
        }],
    })
};

export default data;
