import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, ApiSeo } from './';
import { ApiImage } from './image';

export interface ApiChapter extends ApiSeo {
    id: number,
    showInMenu: boolean,
    name: string,
    menuName?: string,
    content: any[],
    settings?: { [key: string]: any },
    position: number,
    slug?: string,
    bgImageId?: number,
    bgImage?: ApiImage,
}

const rest = {
    delete: (chapterId: number | string): AxiosPromise => {
        return axios.delete(`chapters/${chapterId}`);
    },
    get: (chapterId: number): AxiosPromise<ApiChapter> => {
        return axios.get(`chapters/${chapterId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiChapter[]>> => {
        return axios.get(`chapters?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (chapter: Partial<ApiChapter>): AxiosPromise<ApiChapter> => {
        return axios.post(`chapters${chapter.id ? `/${chapter.id}` : ''}`, chapter);
    },
}

export default rest;
