import { faTag } from '@fortawesome/pro-light-svg-icons';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import styles from './styles.module.scss';

const Block: FC<BlockProps> = ({ data }): JSX.Element => {
    return (
        <Segment className={styles.segment}>
            <div className={styles.container}>
                <div>
                    <FontAwesomeIcon icon={faCaretRight} />
                    <h3>{getValue(data, 'title')}</h3>
                </div>
                <span>{getValue(data, 'label')}</span>
            </div>
        </Segment>
    );
}

const data = {
    Block,
    icon: faTag,
    getData: () => ({
        block: 'label',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'title',
            type: 'text',
            label: 'Title',
            value: '',
        }, {
            id: 'label',
            type: 'text',
            label: 'Tag/Label',
            value: '',
        }],
    })
};

export default data;
