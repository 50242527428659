import React, { FC, HTMLAttributes, CSSProperties, isValidElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { OrderType } from './';
import styles from './styles.module.scss';

export interface TableCellProps extends HTMLAttributes<HTMLTableDataCellElement & HTMLTableHeaderCellElement> {
    align?: 'center' | 'left' | 'right',
    as?: 'td' | 'th',
    collapsing?: boolean,
    icon?: IconDefinition | JSX.Element,
    onSort?: (orderBy: string, order: OrderType) => void
    order?: OrderType,
    sorted?: [string, string],
}

const TableCell: FC<TableCellProps> = ({
    align,
    as,
    children,
    className,
    collapsing,
    icon,
    onSort,
    order,
    sorted,
    ...props
}): JSX.Element => {
    const Elem = as ? as : 'td';

    const style: CSSProperties = {};
    if (props.onClick) {
        style.cursor = 'pointer';
    }

    const handleSort = (e: any): void => {
        if (onSort && sorted) {
            onSort(sorted[1], order === 'ASC' ? 'DESC' : 'ASC');
        }

        if (props.onClick) {
            props.onClick(e);
        }
    }
    
    return (
        <Elem
            className={[
                collapsing && styles.collapsing,
                align && styles[`${align}Align`],
                className,
            ].join(' ')}
            style={style}
            onClick={(e) => handleSort(e)}
            {...props}
            >
            {icon && (isValidElement(icon) ? icon : <FontAwesomeIcon icon={icon as IconDefinition} style={{ marginRight: children ? 5 : 0 }} />)}
            {children}
            {sorted && sorted[0] === sorted[1] && <FontAwesomeIcon
                icon={order === 'ASC' ? faArrowUp : faArrowDown}
                style={{
                    cursor: 'pointer',
                    marginLeft: 10,
                }}
            />}
        </Elem>
    );
}

export default TableCell;
