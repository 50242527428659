import React, { FC } from 'react';
import styles from './styles.module.scss';

interface CharacterCountProps {
    max: number,
    value: string,
}

const CharacterCount: FC<CharacterCountProps> = ({ max, value }): JSX.Element => {
    return (
        <div
            className={[
                styles.counter,
                value.length >= max ? styles.counterError : '',
            ].join(' ')}
        >
            {value.length} / {max} characters
        </div>
    );
}

export default CharacterCount;
