import { faPlus, faPencil, faTrashAlt, faRetweet, faCheck } from '@fortawesome/pro-light-svg-icons';
import { sortBy } from 'lodash';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../RbKit';
import api, { ApiMeta } from '../../api';
import { ApiChapter } from '../../api/chapter';
import SortMode from './SortMode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChapterListView: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ chapters, setChapters ] = useState<ApiChapter[]>([]);
    const [ sortMode, setSortMode ] = useState<boolean>(false);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listChapters({ query, page }).then(({ data }) => {
            setMeta(data.meta);
            setChapters(data.data);
            setIsLoading(false);
        });
    }

    const deleteChapter = (chapterId: number | string): void => {
        api.deleteChapter(chapterId).then(() => {
            fetch();
            toast('Page deleted successfully');
        });
    }

    return (<>
        <PageHeader title="Pages" breadcrumb={{'/pages': 'Overview'}}>
            <Button
                href="/pages/create"
                icon={faPlus}
                padding="compact"
                primary
            />
            <Button
                icon={faRetweet}
                label="Navigation"
                onClick={() => setSortMode(true)}
            />
        </PageHeader>

        {sortMode ? (
            <SortMode
                onCancel={() => setSortMode(false)}
                onUpdate={() => {
                    setSortMode(false);
                    fetch();
                }}
                items={sortBy(chapters.filter(o => o.showInMenu), 'position')}
            />
        ) : (<>
            <Segment isLoading={isLoading}>
                <Table.Actions
                    autoLoad
                    onSearch={fetch}
                    remember
                />
                <Table fluid>
                    <thead>
                        <Table.Row>
                            <Table.HeaderCell>
                                Naam
                            </Table.HeaderCell>
                            <Table.HeaderCell collapsing>
                                In menu
                            </Table.HeaderCell>
                            <Table.HeaderCell collapsing />
                        </Table.Row>
                    </thead>
                    <tbody>
                        {chapters.length > 0 ? chapters.map((chapter) => (
                            <Table.Row key={`row-${chapter.id}`}>
                                <Table.Cell>
                                    <Link to={`/pages/${chapter.id}/edit`}>
                                        {chapter.name}
                                    </Link>
                                </Table.Cell>
                                <Table.Cell align="center" collapsing>
                                    {chapter.showInMenu && <FontAwesomeIcon icon={faCheck} className="success" />}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <Menu dropdown>
                                        <Menu.Item
                                            icon={faPencil}
                                            content="Edit"
                                            href={`/pages/${chapter.id}/edit`}
                                        />
                                        <Menu.Divider />
                                        <Confirm
                                            content="Are you sure you wish to delete this page? This action cannot be undone"
                                            onConfirm={() => deleteChapter(chapter.id)}
                                            trigger={<Menu.Item
                                                icon={faTrashAlt}
                                                content="Delete"
                                            />}
                                        />
                                    </Menu>
                                </Table.Cell>
                            </Table.Row>
                        )) : (
                            <Table.Row noResults />
                        )}
                    </tbody>
                </Table>
            </Segment>
            {meta && <Table.Pagination
                meta={meta}
                onChange={(page: number) => fetch('', page)}
            />}
        </>)}
    </>);
}

export default ChapterListView;
