import { faVimeoSquare } from '@fortawesome/free-brands-svg-icons';
import React, { FC } from 'react';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';

const parseVimeoId = (url: string) => {
    const split = url.split('?')[0].replace(/\/$/, '').split('/');
    return split[split.length - 1];
}

const Block: FC<BlockProps> = ({ data }): JSX.Element => {
    return (
        <Segment>
            <div style={{ paddingBottom: '56.25%', position: 'relative' }}>
                <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    src={`https://player.vimeo.com/video/${parseVimeoId(getValue(data, 'videoId'))}`}
                    style={{ width: '100%', border: 0, height: '100%', position: 'absolute' }}
                    title="Vimeo Video"
                />
            </div>
        </Segment>
    );
}

const data = {
    Block,
    icon: faVimeoSquare,
    getData: () => ({
        block: 'video',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'videoId',
            type: 'text',
            label: 'Vimeo URL',
            value: 'https://vimeo.com/259411563',
        }],
    })
};

export default data;
