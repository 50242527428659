import React, { FC, HTMLAttributes } from 'react';

interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
    noResults?: string | boolean,
}

const TableRow: FC<TableRowProps> = ({ children, noResults }): JSX.Element => {
    return (
        <tr>
            {noResults ? (
                <td colSpan={100}>
                    <em>{noResults === true ? 'No results' : noResults}</em>
                </td>
            ) : children}
        </tr>
    );
}

export default TableRow;
