import { faImage } from '@fortawesome/pro-regular-svg-icons';
import React, { FC } from 'react';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import ImageThumb from '../../../../components/ImageThumb';

const Block: FC<BlockProps> = ({ data }): JSX.Element => {
    const mobile = getValue(data, 'mobileImage');

    return (
        <Segment>
            <ImageThumb
                square
                image={getValue(data, 'image')}
            />
            {mobile && (
                <ImageThumb
                    square
                    image={mobile}
                />
            )}
        </Segment>
    );
}

const data = {
    Block,
    icon: faImage,
    getData: () => ({
        block: 'image',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'image',
            type: 'image',
            label: 'Image',
            value: '',
        }, {
            id: 'mobileImage',
            type: 'image',
            label: 'Mobile image',
            value: '',
        }, {
            id: 'zoom',
            type: 'checkbox',
            label: 'Enable zoom',
            value: false,
        }, {
            id: 'alt',
            type: 'text',
            label: 'Alt text',
            value: '',
        }, {
            id: 'caption',
            type: 'text',
            label: 'Caption',
            value: '',
        }, {
            id: 'link',
            type: 'text',
            label: 'Link',
            value: '',
        }, {
            id: 'width',
            type: 'text',
            label: 'Image width (px or %), empty for default width',
            value: '',
        }],
    })
};

export default data;
