import axios from 'axios';
import auth from './auth';
import chapter from './chapter';
import document from './document';
import image, { ApiImage } from './image';
import user from './user';

export const getToken = () => {
    if (localStorage.getItem('accessToken')) {
        return localStorage.getItem('accessToken');
    }
    
    return null;
}

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common = { 'X-Requested-With': 'XMLHttpRequest' };

axios.interceptors.request.use((config) => {
    const accessToken = getToken(); 
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
});

axios.interceptors.response.use((res: any) => res, (err: any) => {
    if (err.message.indexOf(401) !== -1) {
        localStorage.removeItem('accessToken');
    } else if (err.message.indexOf(403) !== -1) {
        window.location.href = '/';
    }
    return Promise.reject(err);
});

export interface ApiMeta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number,
}

export interface ApiSeo {
    seoTitle: string,
    seoDescription: string,
    seoUrl: string,
    seoImageId?: number,
    seoImage?: ApiImage,
    seoNoIndex: boolean,
}

export interface ApiPaginatedResponse<T> {
    data: T,
    meta: ApiMeta,
    links: {
        first: string,
        last: string,
        next?: string,
        prev?: string,
    },
}

export const request = axios;

const api = {
    getProfile: auth.getProfile,
    login: auth.login,
    logout: auth.logout,
    
    deleteChapter: chapter.delete,
    getChapter: chapter.get,
    listChapters: chapter.list,
    putChapter: chapter.put,
    
    deleteDocument: document.delete,
    getDocument: document.get,
    listDocuments: document.list,
    uploadDocument: document.upload,

    deleteImage: image.delete,
    getImage: image.get,
    listImages: image.list,
    uploadImage: image.upload,
    
    deleteUser: user.delete,
    getUser: user.get,
    listUsers: user.list,
    putUser: user.put,
};

export default api;
