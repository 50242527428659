import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Checkbox, Form, PageHeader, Segment, toast } from '../../RbKit';
import api, { ApiSeo } from '../../api';
import { ApiChapter } from '../../api/chapter';
import ChangeHandler from '../../components/ChangeHandler';
import ContentEditor from '../../modules/ContentEditor';
import SeoView from '../../modules/Seo';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import MediaSelector from '../../components/MediaSelector';
import { ApiImage } from '../../api/image';

interface ChapterEditProps extends RouteComponentProps<{ id?: string }> {
}

const ChapterEditView: FC<ChapterEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ chapter, setChapter ] = useState<Partial<ApiChapter>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const [ bgImage, setBgImage ] = useState<ApiImage>();

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getChapter(parseInt(id)).then(({ data }) => {
                setChapter(data);
                setBgImage(data.bgImage);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setChapter({
            ...chapter,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putChapter(chapter).then(({ data }) => {
            setIsLoading(false);
            setChangesMade(false);
            toast('Page saved succesfully');
            history.push(`/pages/${data.id}/edit`);
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Something went wrong', 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/pages': 'Pages',
                [`/pages/${id ? `${id}/edit` : 'create'}`]: id ? chapter.name || 'New' : 'New',
            }}
            title={`${id ? 'Edit' : 'Create'} page`}
        >
            <Button
                href={`https://foundationmedicine.mwit-demo.nl${chapter.slug || '/'}`}
                icon={faExternalLink}
                label="View page"
                target="_blank"
            />
        </PageHeader>
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Group>
                    <Checkbox
                        checked={chapter.showInMenu}
                        label="Show in menu"
                        onChange={({ checked }: any) => handleInput({ name: 'showInMenu', value: checked })}
                    />
                </Form.Group>
                <Form.Input
                    error={errors.name}
                    label="Title"
                    name="name"
                    onChange={handleInput}
                    required
                    value={chapter.name || ''}
                />
                {chapter.showInMenu && (
                    <Form.Input
                        label="Menu label"
                        name="menuName"
                        onChange={handleInput}
                        value={chapter.menuName || ''}
                    />
                )}
                <Form.Group>
                    <label>Background Image</label>
                    <MediaSelector
                        onSelect={(img) => {
                            handleInput({ name: 'bgImageId', value: img.id })
                            setBgImage(img);
                        }}
                        thumb={bgImage}
                    />
                </Form.Group>
            </Segment>
            <SeoView data={chapter as ApiSeo} handleInput={handleInput} />
            <ContentEditor
                content={{
                    blocks: chapter.content,
                    settings: chapter.settings,
                }}
                onUpdate={(content) => {
                    setChapter({
                        ...chapter,
                        content,
                    });
                    setChangesMade(true);
                }}
                onSettingsUpdate={(id, values) => {
                    setChapter({
                        ...chapter,
                        settings: {
                            ...(chapter.settings || {}),
                            [id]: values,
                        },
                    });
                    setChangesMade(true);
                }}
            />
        </Form>
    </>);
}

export default ChapterEditView;
