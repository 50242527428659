import axios from 'axios';
import { setProfile } from '../reducers/auth';
import store from '../store';

export interface ApiProfile {
    id: string,
    first_name: string,
    last_name: string,
    role: string,
}

const rest = {
    getProfile: (): Promise<ApiProfile> => {
        return axios.get('auth/profile').then(({ data }: { data: ApiProfile }) => {
            store.dispatch(setProfile(data));
            return data;
        });
    },
    login: (emailAddress: string): Promise<ApiProfile> => {
        return axios.post('auth/login', { emailAddress });
    },
    logout: (): void => {
        localStorage.removeItem('accessToken');
        store.dispatch(setProfile());
    }
}

export default rest;
