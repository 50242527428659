import React, { FC, useEffect, useState } from 'react';
import { ApiSeo } from '../../api';
import { ApiImage } from '../../api/image';
import MediaSelector from '../../components/MediaSelector';
import { slugify } from '../../lib/util';
import { Checkbox, Form, Grid, Segment } from '../../RbKit';
import CharacterCount from './CharacterCount';
import styles from './styles.module.scss';

interface SeoProps {
    data: ApiSeo,
    handleInput: ({ name, value }: { [key: string]: any }) => void,
}

const SeoView: FC<SeoProps> = ({ handleInput, data }) => {
    const [ image, setImage ] = useState<ApiImage>();
    useEffect(() => {
        if(!image) {
            setImage(data.seoImage);
        }
    }, [data, image]);

    const setUrl = (): void => {
        if (data.seoTitle === '' || (data.seoUrl && data.seoUrl !== '')) return;
        handleInput({
            name: 'seoUrl',
            value: slugify(data.seoTitle),
        });
    }

    return (
        <Segment>
            <h4>SEO settings</h4>
            <Checkbox
                label="NOINDEX"
                checked={data.seoNoIndex}
                onChange={({ checked }: any) => handleInput({ name: 'seoNoIndex', value: checked })}
            />
            {!data.seoNoIndex && (
                <Grid.Row style={{ marginTop: '1rem' }}>
                    <Grid.Column sm={8}>
                        <Form.Input
                            label="Title"
                            name="seoTitle"
                            onChange={handleInput}
                            onBlur={setUrl}
                            value={data.seoTitle || ''}
                        />
                        <CharacterCount value={data.seoTitle || ''} max={60} />
                        <Form.Input
                            label="URL"
                            name="seoUrl"
                            onChange={handleInput}
                            value={data.seoUrl || ''}
                        />
                        <CharacterCount value={data.seoUrl || ''} max={65} />
                        <Form.Textarea
                            label="Description"
                            name="seoDescription"
                            onChange={handleInput}
                            rows={4}
                            value={data.seoDescription || ''}
                        />
                        <CharacterCount value={data.seoDescription || ''} max={255} />
                    </Grid.Column>
                    <Grid.Column sm={4}>
                        <Form.Group>
                            <label>Image</label>
                            <MediaSelector
                                onSelect={(img) => {
                                    handleInput({ name: 'seoImageId', value: img.id })
                                    setImage(img);
                                }}
                                thumb={image}
                            />
                        </Form.Group>

                        <Segment className={styles.preview}>
                            <span className={styles.previewTitle}>
                                {(data.seoTitle || 'No title set').substr(0, 60)}
                            </span>
                            <span className={styles.previewUrl}>
                                {process.env.REACT_APP_BASE_URL}{data.seoUrl}
                            </span>
                            <span className={styles.previewCopy}>
                                {(data.seoDescription || '').substr(0, 255)}
                            </span>
                        </Segment>
                    </Grid.Column>
                </Grid.Row>
            )}
        </Segment>
    );
}

export default SeoView;
