import { faCopy, faFileAlt, faFileExcel, faFilePdf, faFileWord, faTrashAlt, faUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, RefObject, useState } from 'react';
import api, { ApiMeta } from '../../api';
import { ApiDocument } from '../../api/document';
import { Button, Confirm, Menu, PageHeader, Progress, Segment, Table, toast } from '../../RbKit';

interface UploadButtonProps {
    onUpload: (e: any) => void,
}

export const UploadButton:FC<UploadButtonProps> = ({ onUpload }) => {
    const fileInputRef: RefObject<HTMLInputElement> = React.createRef();

    return (<>
        <Button
            icon={faUpload}
            onClick={() => fileInputRef.current?.click()}
            primary
        />
        <input
            multiple
            onChange={onUpload}
            ref={fileInputRef}
            type="file"
            style={{ display: 'none' }}
        />
    </>);
}

const DocumentView: FC = (): JSX.Element => {
    const [ documents, setDocuments ] = useState<ApiDocument[]>([]);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ remQuery, setQuery ] = useState<string>('');
    const [ isUploading, setIsUploading ] = useState<boolean>(false);
    const [ uploadProgress, setUploadProgress ] = useState<number>(0);
    const [ totalUploadProgress, setTotalUploadProgress ] = useState<number>(0);

    const deleteDocument = (documentId: number): void => {
        api.deleteDocument(documentId).then(() => {
            toast('Document deleted succesfully');
            setDocuments([...documents.filter(o => o.id !== documentId)]);
        });
    }

    const handleSearch = (query?: string, page?: number): void => {
        setIsLoading(true);
        if (query) {
            setQuery(query);
        }

        api.listDocuments({ query: query || remQuery, page: page || 1 }).then(({ data }) => {
            setIsLoading(false);
            setDocuments(data.data);
            setMeta(data.meta);
        });
    }

    const handleFileUpload = (e: any): void => {
        setIsUploading(true);
        uploadFile(e.target.files, 0, e.target.files.length);
    }

    const uploadFile = (files: FileList, index: number, max: number): void => {
        setTotalUploadProgress(index / max);
        setUploadProgress(0);

        if (index >= max) {
            setIsUploading(false);
            return;
        }

        api.uploadDocument(files[index], (progressEvent: any) => {
            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }).then(({ data }) => {
            setDocuments([data, ...documents]);
            uploadFile(files, index + 1, max);
        });
    }

    const handleCopy = (str: string) => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        toast('Copied URL');
    }

    return (<>
        <PageHeader
            breadcrumb={{'media/documents': 'Media'}}
            title="Documents"
        >
            <UploadButton onUpload={(e: any) => handleFileUpload(e)} />
        </PageHeader>
        
        {isUploading && <Segment>
            <Progress progress={uploadProgress} style={{ marginBottom: '.5rem' }} />
            <Progress progress={totalUploadProgress * 100} />
        </Segment>}

        <Segment isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={handleSearch}
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell collapsing />
                        <Table.HeaderCell>
                            Filename
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            align="center"
                            collapsing
                        >
                            URL
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {documents.length > 0 ? documents.map((document) => (
                        <Table.Row key={`row-${document.id}`}>
                            <Table.Cell>
                                {/pdf/i.test(document.extension) && <FontAwesomeIcon icon={faFilePdf} style={{ color: '#ed8b00' }} />}
                                {/xls|csv/i.test(document.extension) && <FontAwesomeIcon icon={faFileExcel} style={{ color: '#008000' }} />}
                                {/doc/i.test(document.extension) && <FontAwesomeIcon icon={faFileWord} style={{ color: '#004db0' }} />}
                                {/txt/i.test(document.extension) && <FontAwesomeIcon icon={faFileAlt} />}
                            </Table.Cell>
                            <Table.Cell>
                                <a
                                    href={document.src}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {document.name}
                                </a>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Button
                                    icon={faCopy}
                                    onClick={() => handleCopy(document.src)}
                                    trigger
                                />
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Confirm
                                        content="Are you sure you wish to delete this document? This action cannot be undone"
                                        onConfirm={() => deleteDocument(document.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt}
                                            content="Delete"
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            infinite
            meta={meta}
            onChange={(page) => handleSearch(undefined, page)}
        />}
    </>);
}

export default DocumentView;
