import React, { FC, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NotificationCenter } from './RbKit';
import api from './api';
import TopBar from './modules/TopBar';
import Drawer from './modules/Drawer';
import Loader from './RbKit/elements/Loader';
import { AppState } from './store';
import styles from './styles.module.scss';
import UserListView from './views/admin/UserView';
import UserEditView from './views/admin/UserView/Edit';
import LoginView from './views/auth/LoginView';
import ChapterListView from './views/ChapterView';
import ChapterEditView from './views/ChapterView/Edit';
import DocumentListView from './views/DocumentView';
import ImageListView from './views/ImageView';
import ImageViewModal from './views/ImageView/Modal';
import { hasRole } from './lib/auth';

const App: FC = (): JSX.Element => {
    const [ isLoading, setIsloading ] = useState<boolean>(true);
    const profile = useSelector((state: AppState) => state.auth.profile);

    useEffect(() => {
        api.getProfile().finally(() => setIsloading(false));
    }, []);

    return (
        <Router>
            <TopBar />
            {isLoading ? (
                <Loader />
            ) : profile ? (<>
                <Drawer />
                <main className={styles.main}>
                    <div>
                    <Switch>
                        <Route component={ChapterListView} exact path="/pages" />
                        <Route component={ChapterEditView} exact path="/pages/create" />
                        <Route component={ChapterEditView} exact path="/pages/:id/edit" />

                        <Route component={DocumentListView} exact path="/media/documents" />
                        <Route component={ImageListView} exact path="/media/images" />
                        
                        {hasRole(profile, 'Administrator') && (<>
                            <Route component={UserListView} exact path="/admin/users" />
                            <Route component={UserEditView} exact path="/admin/users/create" />
                            <Route component={UserEditView} exact path="/admin/users/:id/edit" />
                        </>)}
                    </Switch>
                    </div>
                </main>
                <ImageViewModal open={false} />
            </>) : (
                <Switch>
                    <Route component={LoginView} path="*" />
                </Switch>
            )}
            <NotificationCenter />
        </Router>
    )
}

export default App;
