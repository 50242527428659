import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import { ApiImage } from '../../api/image';
import { fitImage } from '../../lib/image';
import { Confirm, Menu } from '../../RbKit';
import styles from './styles.module.scss';

const PLACEHOLDER =  `${process.env.REACT_APP_API_BASE_URL}media/placeholder.png`;

interface ImageThumbProps {
    confirm?: string,
    image?: Partial<ApiImage>,
    onDelete?: (imageId: number) => void;
    square?: boolean,
    width?: number,
}

const ImageThumb: FC<ImageThumbProps> = ({ confirm, image, onDelete, square, width }): JSX.Element => {
    const [ extraStyle, setExtraStyle ] = useState<any>({});
    useEffect(() => {
        if (square) {
            setExtraStyle({
                height: width || 150,
                paddingBottom: 0,
                width,
            });
        }
    }, [square, width]);

    const handleDelete = (image?: Partial<ApiImage>) => {
        if (onDelete && image && image.id) {
            onDelete(image.id);
        }
    }

    return (
        <div
            className={styles.thumbnail}
            style={{
                backgroundImage: `url(${fitImage(image ? image.src || PLACEHOLDER : PLACEHOLDER, 200)})`,
                ...extraStyle,
            }}
        >
            {onDelete && <div className={styles.menu}>
                <Menu dropdown>
                    {image && image.id && <>
                        <Confirm
                            content={confirm || 'Are you sure you wish to delete this image?'}
                            onConfirm={() => handleDelete(image)}
                            trigger={<Menu.Item
                                icon={faTrashAlt}
                                content="Verwijderen"
                            />}
                        />
                    </>}
                </Menu>
            </div>}
        </div>
    );
}

export default ImageThumb;
