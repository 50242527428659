import { faHorizontalRule } from '@fortawesome/pro-regular-svg-icons';
import React, { FC } from 'react';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import styles from './styles.module.scss';

const Block: FC<BlockProps> = ({ data }): JSX.Element => {
    return (
        <Segment>
            <div className={`${styles.divider} ${styles[getValue(data, 'color')]}`} />
        </Segment>
    );
}

const data = {
    Block,
    icon: faHorizontalRule,
    getData: () => ({
        block: 'divider',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'color',
            type: 'dropdown',
            label: 'Color',
            value: 'black',
            props: {
                options: [{
                    text: 'Black',
                    value: 'black',
                }, {
                    text: 'Orange',
                    value: 'orange',
                }]
            }
        }],
    })
};

export default data;
