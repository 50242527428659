import React, { FC, useEffect, useState } from 'react';
import { ApiImage } from '../../api/image';
import MediaSelector from '../../components/MediaSelector';
import { BlockData, Button, Checkbox, Form, Modal } from '../../RbKit';

interface SettingsModalProps {
    item: BlockData,
    open: boolean,
    onClose: () => void,
    settings?: any,
    availableSettings: any[],
}

const SettingsModal: FC<SettingsModalProps> = ({ item, open, onClose, settings, availableSettings }): JSX.Element => {
    const [ values, setValues ] = useState<any>({});

    useEffect(() => {
        const data: any = {};
        availableSettings.map((s) => data[s.id] = (settings || {})[s.id] || s.default || '');
        setValues(data);
    }, [availableSettings, settings]);

    const handleClose = (save?: boolean): void => {
        if (save) {
            dispatchEvent(new CustomEvent('save-block-settings', {
                detail: {
                    id: item.id,
                    values,
                },
            }));
        } else {
            const data: any = {};
            availableSettings.map((s) => data[s.id] = (settings || {})[s.id] || s.default || '');
            setValues(data);
        }

        onClose();
    }

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setValues({
            ...values,
            [name]: value,
        });
    }

    return (
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Cancel"
                        link
                        onClick={() => handleClose()}
                    />
                    <Button
                        label="Save"
                        primary
                        onClick={() => handleClose(true)}
                    />
                </div>
            )}
            header="Edit block settings"
            onClose={() => handleClose()}
            open={open}
            size="medium"
        >
            {availableSettings.map((field, index) => {
                let input = null;

                if (field.type === 'text') {
                    input = (<Form.Input
                        label={field.label}
                        name={field.id}
                        onChange={handleInput}
                        value={values[field.id]}
                    />);
                } else if (field.type === 'image') {
                    input = (<Form.Group>
                        <label>{field.label}</label>
                        <MediaSelector
                            onSelect={(img: ApiImage) => {
                                handleInput({
                                    name: field.id,
                                    value: {
                                        id: img.id,
                                        src: img.src,
                                        width: img.width,
                                        height: img.height,
                                    }
                                });
                            }}
                            thumb={values[field.id]}
                        />
                    </Form.Group>)
                } else if (field.type === 'dropdown') {
                    input = <Form.Dropdown
                        {...field.props}
                        label={field.label}
                        name={field.id}
                        onChange={handleInput}
                        value={values[field.id]}
                    />
                } else if (field.type === 'checkbox') {
                    input = <Checkbox
                        label={field.label}
                        onChange={({ checked }: any) => handleInput({ name: field.id, value: checked })}
                        checked={values[field.id]}
                    />
                }

                return (
                    <div key={`settings-${index}`}>
                        {input}
                    </div>
                );
            })}
        </Modal>
    );
}

export default SettingsModal;
