import { faText } from '@fortawesome/pro-solid-svg-icons';
import React, { FC } from 'react';
import parse from 'html-react-parser';
import { getValue } from '../../../../lib/block';
import { BlockProps, Segment } from '../../../../RbKit';
import styles from './styles.module.scss';

const Block: FC<BlockProps> = ({ data }): JSX.Element => {
    return (
        <Segment className={styles.container}>
            {parse(getValue(data, 'content') || '')}
        </Segment>
    );
}

const data = {
    Block,
    icon: faText,
    settings: [{
        id: 'bg',
        type: 'dropdown',
        label: 'Background color',
        default: 'white',
        props: {
            options: [{
                text: 'White',
                value: 'white',
            }, {
                text: 'Orange',
                value: 'orange',
            }]
        }
    }],
    getData: () => ({
        block: 'text',
        fields: [{
            id: '_anchor',
            type: 'text',
            label: 'Anchor',
            value: '',
        }, {
            id: 'content',
            type: 'wysiwyg',
            label: 'Content',
            value: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec hendrerit augue nisl, vel dictum quam efficitur in. Vivamus aliquet lorem in turpis pretium, ac consectetur massa molestie. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Donec nisl nisi, vehicula at risus et, convallis convallis lacus. Mauris ut pellentesque quam. Cras eros est, fermentum non lorem id, porta ultricies enim. Duis id sapien et erat dictum vulputate congue quis erat. Quisque id finibus mi.</p>',
        }],
    })
};

export default data;
